import React from "react";
import "./header.css";
import HeaderOption from "./HeaderOption";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ChatIcon from "@mui/icons-material/Chat";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Button } from "@mui/material";
import HeaderOptions from "./HeaderOption";
import { Navigate, useNavigate } from "react-router-dom";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { useRecoilState } from "recoil";
import { token } from "../Store/atom";
import axios from "axios";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CreateIcon from '@mui/icons-material/Create';
import logo from '../constants/logo.png';

const Header = () => {
  const [mytoken , setMyToken] = useRecoilState(token)
  // const dispatch = useDispatch();
  // const logoutOfApp = () => {
  //   dispatch(logout());
  //   auth.signOut();
  // };
  const history = useNavigate()

  const handleLogout = () => {
    console.log("called logout");
   
    setMyToken("")

    axios.defaults.headers.common = {'Authorization': ` `}
    history("/login")

  }

  return (
    <div className="header">
      <div className="header__left">
        <img
          src={logo}
          alt="Kateb Online"
        />

        {/* <div className="header__search">
          <SearchIcon />
          <input placeholder="Search" type="text" />
        </div> */}
      </div>

      <div className="header__right">
      <HeaderOption to="" screen={true} Icon={HomeIcon} title="Home" />

      {mytoken?
      
      <>
      
        <HeaderOptions
        to="myFiles"
          screen={true}
          Icon={ContactPageIcon}
          title="Mes Fichiers"
        />
        <HeaderOption
          screen={true}
          Icon={FolderSpecialIcon}
          title="Exemples"
          to="models"

        />
        <HeaderOption  title="Mon Profile" avatar={true} />

        <Button style={{marginLeft:10}} onClick={()=> handleLogout()}>logout</Button>

      </>
    
    
      :
      
      <>
      <HeaderOption to="/register" screen={true} Icon={CreateIcon} title="Register" />
      <HeaderOption to="/login" screen={true} Icon={LockOpenIcon} title="Login" />

</>
      
      }

      </div>
      

  
    </div>
  );
};

export default Header;





// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout';
// import { Link } from 'react-router-dom';

// export default function Header({token}) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   return (
//     <React.Fragment>
//       <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
//         {token ? 
//         <>
        
//         <p>welcome user </p> 
//         <Link to={"/dashboard"} > 
//         <Typography sx={{ minWidth: 100 }}>Dashboard</Typography>

//         </Link>

//         <Link to={"/create"} > 
//         <Typography sx={{ minWidth: 100 }}>create</Typography>

//         </Link>

//         <Link to={"/AdminCategories"} > 
//         <Typography sx={{ minWidth: 100 }}>AdminCategories</Typography>

//         </Link>

        
//         <Link to={"/myfiles"} > 
//         <Typography sx={{ minWidth: 100 }}>myfiles</Typography>

//         </Link>
        

//         <Link to={"/models"} > 
//         <Typography sx={{ minWidth: 100 }}>models</Typography>

//         </Link>

//         </>
         
//         :
//           <>
//           <Link to={"/login"} > 
//         <Typography sx={{ minWidth: 100 }}>Login</Typography>

//         </Link>
//         <Link to={"/register"} > 
//         <Typography sx={{ minWidth: 100 }}>Register</Typography>

//         </Link>
//           </>

//       }
        

//         <Tooltip title="Account settings">
//           <IconButton
//             onClick={handleClick}
//             size="small"
//             sx={{ ml: 2 }}
//             aria-controls={open ? 'account-menu' : undefined}
//             aria-haspopup="true"
//             aria-expanded={open ? 'true' : undefined}
//           >
//             <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
//           </IconButton>
//         </Tooltip>
//       </Box>
//       <Menu
//         anchorEl={anchorEl}
//         id="account-menu"
//         open={open}
//         onClose={handleClose}
//         onClick={handleClose}
//         PaperProps={{
//           elevation: 0,
//           sx: {
//             overflow: 'visible',
//             filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
//             mt: 1.5,
//             '& .MuiAvatar-root': {
//               width: 32,
//               height: 32,
//               ml: -0.5,
//               mr: 1,
//             },
//             '&:before': {
//               content: '""',
//               display: 'block',
//               position: 'absolute',
//               top: 0,
//               right: 14,
//               width: 10,
//               height: 10,
//               bgcolor: 'background.paper',
//               transform: 'translateY(-50%) rotate(45deg)',
//               zIndex: 0,
//             },
//           },
//         }}
//         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
//         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
//       >
//         <MenuItem onClick={handleClose}>
//           <Avatar /> Profile
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <Avatar /> My account
//         </MenuItem>
//         <Divider />
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <PersonAdd fontSize="small" />
//           </ListItemIcon>
//           Add another account
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <Settings fontSize="small" />
//           </ListItemIcon>
//           Settings
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <Logout fontSize="small" />
//           </ListItemIcon>
//           Logout
//         </MenuItem>
//       </Menu>
//     </React.Fragment>
//   );
// }