import React, { useCallback, useEffect, useRef, useState } from 'react'
import Quill from "quill"
import "quill/dist/quill.snow.css"
import { io } from "socket.io-client"
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { token } from './Store/atom'

const TIME_MS = 2000

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["image", "blockquote", "code-block"],
    ["clean"],
]


function TextEditor() {

  const [usertoken, setuserToken] = useRecoilState(token);


    const [socket, setSocket] = useState(null)
    const [quill, setQuill] = useState(null)

    const {id : documentId , category: category } = useParams()
   

 
    // Saving document

    // handeling the same id document
    useEffect(() => {
        if(socket == null || quill == null) return
        const interval = setInterval(() => {
            socket.emit("save-document",quill.getContents() )

        }, TIME_MS);

        return () => {
            clearInterval(interval)
        }

         
        
      }, [socket , quill , documentId])
      
    

    // handeling the same id document
    useEffect(() => {
        if (socket == null || quill == null) return
    
        socket.once("load-document", document => {
        
            console.log("kkkk", );
          quill.setContents(document)
          quill.enable()
        })
    
        socket.emit("get-document", documentId , category)
      }, [socket, quill, documentId])
    

    // launch the socket io
    useEffect(() => {
        const s = io("https://api.kateb.online", {path : "/mysocket" , query: {usertoken}})
        setSocket(s)
        return () => {
            s.disconnect()
        }
    }, [])

    // receive braodcast
    useEffect(() => {
        if (socket == null || quill == null) return
    
        const handler = delta => {
          quill.updateContents(delta)
        }
        socket.on("receive-changes", handler)
    
        return () => {
            socket.off("receive-changes", handler)
        }
      }, [socket, quill])

    // send changes to server to be broadcasted
    useEffect(() => {
        if (socket == null || quill == null) return
    
        const handler = (delta, oldDelta, source) => {
          if (source !== "user") return
          socket.emit("send-changes", delta)
        }
        quill.on("text-change", handler)
    
        return () => {
          quill.off("text-change", handler)
        }
      }, [socket, quill])





    const wrapperRef = useCallback((wrapper) => {
        const editor = document.createElement('div')

        if (wrapper == null) return
        wrapper.innerHTML = ""
        wrapper.append(editor)
        const q = new Quill(editor, { theme: "snow", modules: { toolbar: TOOLBAR_OPTIONS } },)
        q.disable()
        q.setText("Loading , Please Wait ....")
        setQuill(q)
    }, [])

    return (
      <>
      {/* <p>{usertoken}</p> */}
      <div ref={wrapperRef} className="container">


        </div>
      </>
        
    )
}

export default TextEditor