import React from "react";
import "./HeaderOption.css";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

const HeaderOptions = ({ Icon, title, avatar, onClick, screen , to }) => {
  const user = null
  return (
    <Link to={to} > 
       
       
    <div
      onClick={onClick}
      className={`${screen ? "headerOption__screen" : "headerOption"}`}
    >
      <div style={{borderRadius:9 , width:50, alignItems:"center" }}>
      
      <p style={{ textAlign:"center"}}>


      {Icon && <Icon className="headerOption__icon" />}
      
      </p>

      </div>


    </div>
    </Link>
  );
};

export default HeaderOptions;