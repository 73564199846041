import React from 'react'
import "../styles.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate

} from "react-router-dom"
import { v4 as randomId } from 'uuid'
import { useEffect, useState } from "react";

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import Header from '../Landing/Header';
import { token } from '../Store/atom';
import Landing from '../Landing/Landing';
import Files from '../Files/Files';
import Dashboard from '../Client/Dashboard/Dashboard';
import Models from '../Models/Models';
import Login from '../Authentication/Login';
import Register from '../Authentication/Register';
import TextEditor from '../TextEditor';
import axios from 'axios';
import Categories from '../Categories/Categories';
import { BASE_URL } from '../constants/BASE_URL';

function RouteClient() {
    const [usertoken, setuserToken] = useRecoilState(token);

    useEffect(() => {
      if(!usertoken) return
      
      axios.defaults.baseURL = BASE_URL
      axios.defaults.headers.common = {'Authorization': `bearer ${usertoken}`}

      
    }, [usertoken])
    
  return (
    <div className='wrapper'>
       
        <Router>
      {/* <p>token : {usertoken}</p> */}

    <Header token={usertoken} />
    <div className='upper-part'>
      <Routes>
        <Route path="/" element={usertoken ? <Navigate to={"/dashboard"} /> :  <Landing />}  />
        <Route path="/dashboard" element={!usertoken ? <Navigate to={"/login"} /> :  <Dashboard />} />
        <Route path="/myfiles" element={!usertoken ? <Navigate to={"/login"} /> :  <Files />} />
        <Route path="/login" element={usertoken ? <Navigate to={"/dashboard"} /> :  <Login />}  />
        <Route path="/models" element={!usertoken ? <Navigate to={"/login"} /> :  <Models />}  />
        <Route path="/AdminCategories" element={<Categories />}  />
        <Route path="/register" element={usertoken ? <Navigate to={"/dashboard"} /> :  <Register />}  />
        <Route path="/create" element={<Navigate replace to={`/document/${randomId()}/`} />} />
        <Route path="/document/:id" element={<TextEditor />} />
        <Route path="/document/:id/category/:category" element={<TextEditor admin={true} />} />
      </Routes>
      </div>
    </Router>
 

    </div>
  )
}

export default RouteClient