import React from 'react'

function Landing() {
  return (
    <div>
        
        <p>Landing</p>
    </div>
  )
}

export default Landing