
import React, { useEffect, useState } from 'react'
import axios from "axios"
import {Link} from "react-router-dom"
import { v4 as randomId } from 'uuid'

function Categories() {
  const [files, setFiles] = useState(null)

  useEffect(() => {
    if(files) return
    axios.get("/categories")
    .then(response => {
      console.log("res", response.data);
      setFiles(response.data)
    })

  .catch(error => {
    console.log(error);
  })
  
  }, [])
  
  
  return (
    <>
        <div>{files && files.length} Categories </div>

      {files ? 
      files.map(item => {
        return (
          <div key={item._id}>
              <p>{item.name}</p>
              <p>{item._id}</p>
            
              <Link  to={`/document/${randomId()}/category/${item._id}`} >
                <p>add Model to this category</p>
              </Link>
              <hr/>
          </div>
       
        )
      })

    :
    <p>nothing to show</p>
    
    }
    
    </>
  )
}

export default Categories