import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {Link, useNavigate} from "react-router-dom"
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Icon } from '@mui/material';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import axios from 'axios';
import * as moment from 'moment';
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function FileItem({item , model}) {
  
    const history = useNavigate()
    const createDuplication = () => {
        if(!model) return  history(`/document/${item._id}`)

        console.log("createDuplication" , item._id);
        axios.get(`/duplicate/${item._id}`)
        .then(response => {
          console.log(response.data);
          history(`/document/${response.data}`)
    
        })
        .catch(error => {
          console.log(error);
        })
      }
    return (
    <div style={{textDecoration: "None"}}  onClick={() => createDuplication()} >

    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        mb:2,

        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            {model ? <FolderSpecialIcon /> : <TextSnippetOutlinedIcon size={20}   />}
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
     
             
           
              
                
         
   
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
              {item.title}
              </Typography>
              <Typography variant="body2" gutterBottom>
              {item._id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Last Modification : {moment(item.updated_at, "YYYYMMDD").fromNow()} 
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ cursor: 'pointer' }} variant="body2">
              Creation Date : {moment(item.created_at, "YYYYMMDD").fromNow()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Typography variant="subtitle1" component="div">
              $19.00
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
}
