import React, { useEffect, useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import FileItem from '../components/FileItem'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

function Models() {
  const [files, setFiles] = useState(null)
  const [filter, setFilter] = useState(null)
  const [categories, setCategories] = useState(null)
  const history = useNavigate()

  const createDuplication = (id) => {
    console.log("createDuplication" , id);
    axios.get(`/duplicate/${id}`)
    .then(response => {
      console.log(response.data);
      history(`/document/${response.data}`)

    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    if(files) return
    axios.get("/models")
    .then(response => {
      console.log("res", response.data);
      setFiles(response.data)
    })

  .catch(error => {
    console.log(error);
  })
  
  }, [])

  useEffect(() => {
    if(categories) return
    axios.get("/categories")
    .then(response => {
      console.log("res", response.data);
      setCategories(response.data)
    })

  .catch(error => {
    console.log(error);
  })
  
  }, [])
  

  useEffect(() => {
    if(!filter) return 
    axios.get(`/FiltredcategoryDocs/${filter?._id}`)
    .then(response => {
      console.log("res", response.data);
      setFiles(response.data)
    })

  .catch(error => {
    console.log(error);
  })
  
  }, [filter])
  
  return (
    <>
    <Typography variant="h2" component="h2">
    {files?.length} Exemples
</Typography>
        {/* <div>Models {filter?.name} </div> */}
        {categories ? 
                  <Stack  direction="row" spacing={2} marginBottom={2}>

      {categories.map(item => {
        return (
      <Button onClick={()=> setFilter(item)}  key={item._id} style={{display: "inline"}} variant="outlined">{item.name}</Button>
     
        )
        
      })}
          </Stack>  
      

    :
    <p>nothing to show</p>
    
    }


      {files ? 
      files.map(item => {
        return (
          <FileItem model={true} item={item} />
       
        )
      })

    :
    <p>nothing to show</p>
    
    }
    
    </>
  )
}

export default Models 