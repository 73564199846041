import React, { useEffect, useState } from 'react'
import axios from "axios"
import FileItem from '../components/FileItem'
import { Typography } from '@mui/material'

function Files() {
  const [files, setFiles] = useState(null)

  useEffect(() => {
    if(files) return
    axios.get("/myfiles")
    .then(response => {
      console.log("res", response.data);
      setFiles(response.data)
    })

  .catch(error => {
    console.log(error);
  })
  
  }, [])
  
  
  return (
    <>
    <Typography variant="h2" component="h2">
    {files && files.length} Files 
</Typography>
        {/* <div>{files && files.length} Files </div> */}

      {files ? 
      files.map(item => {
        return (
          <div key={item._id}>
            <FileItem item={item} />
              
          </div>
       
        )
      })

    :
    <p>nothing to show</p>
    
    }
    
    </>
  )
}

export default Files 