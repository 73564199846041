import { RecoilRoot } from "recoil";
import RouteClient from "./Route/RouteClient";
import "./styles.css"


function App() {
  

  
  return (
    <>
     <RecoilRoot>
      <RouteClient />
    
    </RecoilRoot>
    </>
    
    

  );
}

export default App;
